import React from "react";
import party_flyer from "../assets/club_social_feb.png";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  Button,
  Grid,
  Paper,
  Box,
  IconButton,
  Avatar,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Countdown, { zeroPad } from "react-countdown";
import InstagramIcon from "@mui/icons-material/Instagram";
import logo_orange from "../assets/logo_orange.PNG";

const MapComponent = () => {
  return (
    <div
      style={{
        marginBottom: "30px",
        display: "flex",
        backgroundColor: "black",
      }}
    >
      <iframe
        title="Location on Google Maps"
        width="350"
        height="350"
        style={{ margin: "auto", display: "flex" }}
        loading="lazy"
        allowFullScreen
        src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJT-vbrRM1K4gR7zGvUnv9XVE&key=AIzaSyBqYTblqvHb_h92smgdLtuLjsyD0jD1Vps"
      ></iframe>
    </div>
  );
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "orange",
  ...theme.typography.body2,
  //padding: theme.spacing(1),
  textAlign: "center",
  fontWeight: 600,
  fontSize: "1.8rem",
}));

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  return (
    <Box
      sx={{ flexGrow: 0, backgroundColor: "black", justifyContent: "center" }}
    >
      <Grid container spacing={5} sx={{ justifyContent: "center" }}>
        <Grid item xs={2.5} sx={{ textAlign: "center" }}>
          <Item>
            {days}
            <p style={{ margin: 0, fontSize: "0.7rem" }}>Days</p>
          </Item>
        </Grid>
        <Grid item xs={2.5} sx={{ textAlign: "center" }}>
          <Item>
            {zeroPad(hours)}
            <p style={{ margin: 0, fontSize: "0.7rem" }}>Hours</p>
          </Item>
        </Grid>
        <Grid item xs={2.5}>
          <Item>
            {zeroPad(minutes)}
            <p style={{ margin: 0, fontSize: "0.7rem" }}>Minutes</p>
          </Item>
        </Grid>
        <Grid item xs={2.5}>
          <Item>
            {zeroPad(seconds)}
            <p style={{ margin: 0, fontSize: "0.7rem" }}>Seconds</p>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
};

export const Content = () => {
  return (
    <Card>
      <CardMedia
        component="img"
        sx={{ backgroundColor: "black" }}
        image={party_flyer}
        title="party-flyer"
      />
      <CardContent sx={{ backgroundColor: "black" }}>
        <Typography
          gutterBottom
          variant="body2"
          component="div"
          align="center"
          sx={{ fontWeight: 600, paddingBottom: 2 }}
        >
          Bringing the community together for an unforgettable night! Event is
          19+. Advance tickets are strongly recommended.
        </Typography>
        <Typography gutterBottom variant="body2" align="center">
          Get ready to party at CLUB SOCIAL, the hottest event in town! Dance
          the night away to the beats of top DJs and enjoy a vibrant atmosphere
          filled with energetic partygoers.
        </Typography>
        <Typography
          gutterBottom
          variant="body2"
          component="div"
          align="center"
          sx={{ paddingBottom: 2 }}
        >
          Don't miss out on this incredible opportunity to let loose and have a
          blast. We can't wait to see you there!
        </Typography>
      </CardContent>
      <CardActions
        sx={{
          backgroundColor: "black",
          justifyContent: "center",
          paddingBottom: 4,
        }}
      >
        <Button
          variant="contained"
          href="https://www.eventbrite.ca/e/club-social-tickets-819469313777"
          size="large"
          sx={{ color: "white", fontWeight: 800, fontSize: "1.1rem" }}
        >
          GET TICKETS
        </Button>
      </CardActions>
      <MapComponent />
      <Countdown date={1708830000000} renderer={renderer} />
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          justifyContent: "center",
          backgroundColor: "black",
          paddingTop: "40px",
        }}
      >
        <IconButton
          id="ig_logo"
          size="large"
          aria-label="instagram"
          sx={{ margin: "auto", color: "white", scale: "1.3" }}
          href="https://www.instagram.com/club.social44/?igshid=MzRlODBiNWFlZA%3D%3D"
        >
          <InstagramIcon />
          <Typography
            variant="body2"
            component="div"
            align="center"
            sx={{ fontWeight: 600, paddingLeft: "10px" }}
          >
            club.social44
          </Typography>
        </IconButton>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          justifyContent: "center",
          backgroundColor: "black",
          paddingTop: "40px",
        }}
      >
        <IconButton size="large" aria-label="logo" sx={{ margin: "auto" }}>
          <Avatar
            sx={{ width: 80, height: 80 }}
            alt="Club Social Logo"
            src={logo_orange}
          />
        </IconButton>
      </Box>
    </Card>
  );
};

export default Content;
