import React from "react";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  IconButton,
  Avatar,
  Box,
} from "@mui/material";
import about from "../assets/about.jpg";
import logo_orange from "../assets/logo_orange.PNG";
import InstagramIcon from "@mui/icons-material/Instagram";

export const About = () => {
  return (
    <Card>
      <CardMedia
        component="img"
        sx={{ backgroundColor: "black" }}
        image={about}
        title="party-flyer"
      />
      <CardContent sx={{ backgroundColor: "black" }}>
        <Typography
          gutterBottom
          variant="body1"
          component="div"
          align="center"
          sx={{ fontWeight: 600, paddingBottom: 2 }}
        >
          At Club Social, we believe in the transformative power of shared
          moments. Our mission is to curate a haven where the heartbeats of art,
          music, fashion, and endless good vibes converge.
        </Typography>
        <Typography gutterBottom variant="body1" align="center">
          With a passion that burns brighter than the city lights, we are
          dedicated to crafting events that transcend the ordinary, becoming
          chapters in your life's story. From moonlit parties to avant-garde
          exhibitions, every detail is woven with love, aiming to etch memories
          that dance vividly in your mind. Step into our world, where each note
          of music, stroke of color, and burst of laughter is carefully
          orchestrated to create a symphony of unforgettable experiences. Join
          us at Club Social, where the stars align to kindle connections, ignite
          passions, and redefine the essence of celebration. Welcome to a realm
          where the boundaries between reality and dreams blur, where you're not
          just a guest, but a cherished part of our journey. Let's come together
          to paint the canvas of memories, one vibrant hue at a time.
        </Typography>
        <Typography
          gutterBottom
          variant="body1"
          component="div"
          align="center"
          sx={{ paddingBottom: 2, fontWeight: 600 }}
        >
          Club Social - Where Every Moment Finds its Magic.{" "}
          <span role="img" aria-label="magic">
            ✨🎶🎨
          </span>
          #ClubsocialMagic
        </Typography>
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            backgroundColor: "black",
            paddingTop: "40px",
          }}
        >
          <IconButton
            id="ig_logo"
            size="large"
            aria-label="instagram"
            sx={{ margin: "auto", color: "white", scale: "1.3" }}
            href="https://www.instagram.com/club.social44/?igshid=MzRlODBiNWFlZA%3D%3D"
          >
            <InstagramIcon />
            <Typography
              variant="body2"
              component="div"
              align="center"
              sx={{ fontWeight: 600, paddingLeft: "10px" }}
            >
              club.social44
            </Typography>
          </IconButton>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            backgroundColor: "black",
            paddingTop: "40px",
          }}
        >
          <IconButton size="large" aria-label="logo" sx={{ margin: "auto" }}>
            <Avatar
              sx={{ width: 80, height: 80 }}
              alt="Club Social Logo"
              src={logo_orange}
            />
          </IconButton>
        </Box>
      </CardContent>
    </Card>
  );
};

export default About;
