import React, { useCallback } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Avatar,
  Button,
  Box,
  Drawer,
  Container,
  List,
  ListItem,
  Link,
} from "@mui/material";
import logo_orange from "../assets/logo_orange.PNG";
import MenuIcon from "@mui/icons-material/Menu";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import theme from "../theme";
import { useNavigate } from "react-router-dom";

const pages = ["ABOUT US", "CONTACT"];

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "space-between",
}));

export const Header = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const navigate = useNavigate();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleAboutCLick = useCallback(() => {
    navigate("/about");
  });

  return (
    <AppBar
      position="sticky"
      color="secondary"
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1 }}>
            <IconButton
              size="large"
              edge="start"
              aria-label="logo"
              sx={{ mr: 2 }}
              href="/"
            >
              <Avatar
                sx={{ width: 80, height: 80 }}
                alt="Club Social Logo"
                src={logo_orange}
              />
            </IconButton>
          </Box>
          <Box sx={{ flexGrow: 0, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="navigation"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
              sx={{ p: 0 }}
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              id="menu-appbar"
              anchor="top"
              variant="persistent"
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <DrawerHeader theme={theme}>
                <IconButton
                  size="large"
                  edge="start"
                  aria-label="logo"
                  sx={{ mr: 2 }}
                >
                  <Avatar
                    sx={{ width: 80, height: 80 }}
                    alt="Club Social Logo"
                    src={logo_orange}
                  />
                </IconButton>
                <IconButton onClick={handleCloseNavMenu}>
                  <CloseIcon sx={{ color: "white" }} />
                </IconButton>
              </DrawerHeader>
              <List>
                <ListItem key={"about"} onClick={handleAboutCLick}>
                  <Typography textAlign="center">
                    <Link underline="none">ABOUT US</Link>
                  </Typography>
                </ListItem>
                <ListItem key={"contact"} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">
                    <Link underline="none" href="#ig_logo">
                      CONTACT
                    </Link>
                  </Typography>
                </ListItem>
              </List>
            </Drawer>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                {page}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
