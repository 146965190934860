import React from "react";
import HomePage from "./pages/home";
import AboutPage from "./pages/about";
import { Routes, Route } from "react-router-dom";

export const App = () => {
  return (
    <Routes>
      <Route exact path="/" element={<HomePage />} />
      <Route exact path="/about" element={<AboutPage />} />
    </Routes>
  );
};

export default App;
